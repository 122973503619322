import React from "react"
import Footer from "../components/footer"
import Header from "../components/header"
import "../styles/index.scss"
import "../styles/layout.scss"
import FlechaSubida from "../components/flechaSubida"
import CookieConsent from "react-cookie-consent"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import { useLocation } from "@reach/router"
import { Link } from "gatsby"
import { motion } from "framer-motion"

export default function Layout(props) {
  const location = useLocation()

  return (
    <div>
      <Header />
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          // type: "spring",
          // damping:20,
          // stiffness: 75,
          duration: 1,
        }}
      >
        <CookieConsent
          location="bottom"
          buttonText="Aceptar"
          declineButtonText="Rechazar"
          cookieName="gatsby-gdpr-google-tagmanager"
          buttonStyle={{ fontFamily: "Now-black-light" }}
          onAccept={() => {
            initializeAndTrack(location)
          }}
        >
          Utilizamos cookies propias y de terceros para analizar el tráfico en
          nuestra web mediante la obtención de los datos necesarios para
          estudiar su navegación. Puedes consultar nuestra{" "}
          <Link to="/politica-de-cookies/">política de cookies</Link>
        </CookieConsent>
        <div className="contenido">
          {props.children}
          <FlechaSubida showBelow={800} />
        </div>
      </motion.main>
      <Footer />
    </div>
  )
}
