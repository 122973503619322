import React from "react"
import { FaInstagram, FaFacebook } from "react-icons/fa"
import { IconContext } from "react-icons"
import "../styles/footer.scss"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

export default function Footer() {
  return (
    <footer className="container">
      <div className="footer-principal">
        <div className="col-1">
          <h3 className="slogan">No has visto nada igual</h3>
          <div className="datos">
            <p className="visita">Visítanos</p>
              <div className="dir-bizkaia">
                <small>Oficina Bizkaia</small>
                <a
                  href="https://goo.gl/maps/ge53LSc5eigRjbss8"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>Paseo Landabarri 2</span>
                  <br />
                  <span>48940, Leioa (Bizkaia)</span>
                </a>
              </div>
              <div className="dir-girona">
                <small>Oficina Girona</small>
                <a
                  href="https://goo.gl/maps/wjuYicB95uFPJgha9"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>Travessera de mar 1</span>
                  <br />
                  <span>17770, Borrassà (Girona)</span>
                </a>
              </div>
              <div className="llama">
                <p>Llámanos</p>
                <a href="tel:+34946941377">+34 946 941 377</a>
              </div>
              <div className="escribe">
                <p>Escríbenos</p>
                <a href="mailto:hola@bullhost.es">hola@bullhost.es</a>
              </div>
          </div>
          {/* <div className="datos">
            <p>Visítanos</p>
            <ul className="lista-datos">
              <li>
                <small>Oficina Bizkaia</small>
                <a
                  href="https://goo.gl/maps/ge53LSc5eigRjbss8"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>Paseo Landabarri 2</span>
                  <br />
                  <span>48940, Leioa (Bizkaia)</span>
                </a>
              </li>
              <li>
                <small>Oficina Girona</small>
                <a
                  href="https://goo.gl/maps/wjuYicB95uFPJgha9"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>Travessera de mar 1</span>
                  <br />
                  <span>17770, Borrassà (Girona)</span>
                </a>
              </li>
              </ul>
              <ul className="lista-datos">
              <li>
                <p>Llámanos</p>
                <a href="tel:+34946941377">+34 946 941 377</a>
              </li>
              <li>
                <p>Escríbenos</p>
                <a href="mailto:hola@bullhost.es">hola@bullhost.es</a>
              </li>
            </ul>
          </div> */}
        </div>
        <div className="col-2">
          <img
            src={"/img/bullhost-black.svg"}
            alt="logo Bullhost"
            className="logo-bullhost"
          />
          <ul className="iconos-redes">
            <IconContext.Provider
              value={{
                color: "#1f1f1f",
                size: "4rem",
                className: "iconos-sociales",
              }}
            >
              <li>
                <a
                  href="https://www.instagram.com/bullhostagency/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Instagram"
                >
                  <FaInstagram />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/BullHostServices"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Facebook"
                >
                  <FaFacebook />
                </a>
              </li>
            </IconContext.Provider>
          </ul>
          <div id="kit-digital">
            <p>Gestionamos tu subvención a través de:</p>
            <a
              href="https://bullhost.security/kit-digital/"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                src="../../static/img/kitdigital-logo.webp"
                style={{ maxWidth: 120 }}
                objectFit="contain"
                alt="Logo Kit Digital"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="avisos">
        <ul>
          <li>
            <Link to="/aviso-legal/">Aviso legal</Link>
          </li>
          <li>
            <Link to="/politica-de-privacidad/">Política de privacidad</Link>
          </li>
          <li>
            <Link to="/politica-de-cookies/">Política de cookies</Link>
          </li>
        </ul>
      </div>
    </footer>
  )
}
