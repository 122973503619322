import React, {useEffect, useState } from 'react'
import {
    AiOutlineArrowUp
} from 'react-icons/ai';
import { IconContext } from "react-icons";
import '../styles/flechaSubida.scss'

// export default function FlechaSubida() {
//     return (
//         <div className="flecha-subida">
//             <IconContext color="#1f1f1f">
//                 <AiOutlineArrowUp />
//             </IconContext>
//         </div>
//     )
// }
const Scroll = ({showBelow}) => {

    const [show, setShow] = useState(showBelow ? false : true)

    const handleScroll = () => {
        if (window.pageYOffset > showBelow) {
            if (!show) setShow(true)
        } else {
            if (show) setShow(false)
        }
    }

    const handleClick = () => {
        window[`scrollTo`]({ top: 0, behavior: `smooth` })
    }

    const handleKeyDown = (ev) => {
      if (ev.keyCode === 13) {
        window[`scrollTo`]({ top: 0, behavior: `smooth` })
       }
    }

    useEffect(() => {
        if (showBelow) {
            window.addEventListener(`scroll`, handleScroll)
            return () => window.removeEventListener(`scroll`, handleScroll)
        }
    })

    return (
        <IconContext.Provider value={{ size: "4rem" }}>
            {show &&
                <button
                className="flecha-subida"
                onClick={handleClick}
                aria-label="Volver arriba"
                onKeyDown={handleKeyDown}
                >
                    <AiOutlineArrowUp />
                </button>
            }
        </IconContext.Provider>
    )
}
export default Scroll
